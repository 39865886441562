.card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  width: 300px;
  margin: 16px;
}

.card-img {
  width: 100%;
  height: auto;
}

.card-content {
  padding: 16px;
}

.card-title {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.card-description {
  margin: 8px 0 16px;
  font-size: 14px;
  color: #666;
}
