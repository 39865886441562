.header_toolbar {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #006036;
}

.header_navTools {
  width: 50%;
  display: flex;
  justify-content: space-around;
  color: #fff;
}

.header_whatsapp {
  width: 20%;
}

.link {
  text-decoration: none;
}

.link.color-black {
  color: #22232b;
}

.header_phone {
  width: 25%;
}

.header_logo {
  margin-left: 5%;
  width: 15%;
}

.header_whatsapp:before {
  content: '';
  display: block;
  background: url('../../assets/images/whatsApp.png') no-repeat;
  width: 22px;
  height: 22px;
  background-size: 100% 100%; /* Добавьте это свойство */
  float: left;
  margin: 0 3px 0 0;
}

.header_phone:before {
  content: '';
  display: block;
  background: url('../../assets/images/phoneIcon.svg') no-repeat;
  width: 22px;
  height: 22px;
  background-size: 100% 100%; /* Добавьте это свойство */
  float: left;
  margin: 0 3px 0 0;
}

.header_logo {
  border-radius: 50%;
  width: 100px;
}

@media (max-width: 600px) {
  .header_toolbar {
    flex: 1 1 50%;
    height: 112px;
    padding-bottom: 5px;
  }
  .header_logo {
    border-radius: 50%;
    width: 60px;
  }
  .header_navTools {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .header_whatsapp {
    width: 100%;
    margin-bottom: 15px;
  }
  .header_phone {
    width: 100%;
    margin: 15px 0;
  }
}

@media (min-width: 601px) {
  .header_toolbar {
    height: 100px;
  }
  .header_logo {
    border-radius: 50%;
    width: 80px;
  }
  .header_navTools {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header_whatsapp {
    width: 33%;
  }
  .header_phone {
    width: 33%;
    margin: 15px 0;
  }
}
