.lead_info {
  height: 600px;
  background: url('../../assets/images/services/mainBG.png') no-repeat;
  background-size: cover;
  position: relative;
}

.lead_text_block {
  position: absolute;
  top: 5%;
  width: 38%;
  font-size: 26px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0px 0px 50px 50px;
}

.lead__text {
  font-size: 24px;
}

.lead_button {
  position: absolute;
  left: 7%;
  bottom: -5%;
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.link {
  color: #ffffff;
}

.link.color-black {
  color: #22232b;
}

@media (max-width: 600px) {
  .lead_info {
    height: 350px;
    background: none;
    background-color: #006036;
    position: relative;
  }
  .lead_text_block {
    font-size: 14px;
    width: 75%;
    top: 5%;
  }

  .lead__text {
    font-size: 16px;
  }

  .lead_button {
    position: absolute;
    left: 7%;
    bottom: -33%;
    flex-direction: column;
    justify-content: space-around;
    height: 130px;
    width: 90%;
    font-size: 14px;
  }
}

@media (min-width: 601px) {
  .lead_info {
    height: 600px;
    background-color: #006036;
    position: relative;
  }
  .lead_text_block {
    font-size: 20px;
    width: 50%;
    top: 2%;
  }

  .lead__text {
    font-size: 18px;
  }

  .lead_button {
    left: 7%;
    bottom: -33%;
    flex-direction: column;
    justify-content: space-around;
    height: 130px;
    width: 60%;
    font-size: 16px;
  }
}

@media (min-width: 1024px) {
  .lead_button {
    position: absolute;
    left: 7%;
    bottom: -33%;
    width: 60%;
  }
}
