.footer {
  background-color: #006036;
}

.footer_block {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.footer_logo {
  border-radius: 50%;
  width: 100px;
  margin-bottom: 10px;
}

.instagram {
  margin-top: 10px;
}

@media (max-width: 600px) {
  .footer_logo {
    border-radius: 50%;
    width: 50px;
  }
  .footer_block {
    flex-direction: column;
    height: 150px;
    justify-content: space-between;
    font-size: 14px;
  }
}
