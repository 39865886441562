/* styles.css */
:root {
  --primary-color: #ffc900;
  --primary-hover-color: #e6b800;
  --primary-active-color: #cc9f00;

  --secondary-color: #888f95; /* оптимальный серый цвет */
  --secondary-hover-color: #5a6268;
  --secondary-active-color: #4e555b;

  --blue-color: #1976d2;
  --blue-hover-color: #315f8d;
  --blue-active-color: #455361;
}

.custom-button {
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-button.primary {
  background-color: var(--primary-color);
}

.custom-button.primary:hover {
  background-color: var(--primary-hover-color);
}

.custom-button.primary:active {
  background-color: var(--primary-active-color);
}

.custom-button.secondary {
  background-color: var(--secondary-color);
}

.custom-button.secondary:hover {
  background-color: var(--secondary-hover-color);
}

.custom-button.secondary:active {
  background-color: var(--secondary-active-color);
}

.custom-button.blue {
  background-color: var(--blue-color);
}

.custom-button.blue:hover {
  background-color: var(--blue-hover-color);
}

.custom-button.blue:active {
  background-color: var(--blue-active-color);
}

.custom-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(221, 219, 219, 0.2);
}

.custom-button.primary.size-20 {
  font-size: 20px;
}

.custom-button.primary.size-14 {
  font-size: 14px;
}

.custom-button.secondary.size-20 {
  font-size: 20px;
}
