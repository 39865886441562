.advantages {
  color: #22232b;
  margin: 30px 20px 30px 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.advantages__cards {
  display: flex;
  justify-content: center;
}

@media (max-width: 600px) {
  .advantages__cards {
    flex-direction: column;
    flex-wrap: wrap;
  }
}

@media (max-width: 1024px) {
  .advantages__cards {
    flex-direction: column;
    flex-wrap: wrap;
  }
}
