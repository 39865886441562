.faq-container {
  max-width: 800px;
  height: 550px;
  margin: 0 auto;
  padding: 20px;
}

.faq_block {
  background-image: url('../../assets/images//services/faqbg6.png');
}
.faq-title {
  text-align: center;
  margin-bottom: 40px;
}

.faq-list {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.faq-item {
  border-bottom: 1px solid #ddd;
}

.faq-item:last-child {
  border-bottom: none;
}

.faq-question {
  background-color: #f5f5f5;
  padding: 16px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s ease;
}

.faq-question:hover {
  background-color: #ebebeb;
}

.faq-answer {
  padding: 16px;
  background-color: #fff;
  border-top: 1px solid #ddd;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.faq-question:after {
  content: '+';
  font-size: 24px;
}

.faq-question.open:after {
  content: '-';
}

@media (max-width: 600px) {
  .faq-container {
    font-size: 12px;
    width: 85%;
  }
}

@media (min-width: 601px) {
  .faq-container {
    font-size: 12px;
    width: 68%;
  }
}

@media (min-width: 720px) {
  .faq-container {
    font-size: 14px;
    width: 68%;
  }
}

@media (min-width: 970px) {
  .faq-container {
    width: 68%;
  }
}
