.services_block {
  background-image: url('../../assets/images//services/servicesBG.svg');
  background-size: cover;
  padding-bottom: 20px;
}

.services_cards {
  color: #ffffff;
  margin: 30px 20px 0 3%;
  padding: 20px 0;
}

.yellow {
  color: #ffc900;
}

.custom-list {
  list-style: none;
  padding: 0;
}

.custom-list li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 8px;
}

.custom-list li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background-image: url('../../assets/images//services/galochka.png');
  background-size: 100% 100%;
}
.service_cards_elements {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 23%;
}

@media (max-width: 600px) {
  .services_block {
    background: none;
    background-color: #006036;
  }
  .service_cards_elements {
    width: 90%;
  }
}

@media (min-width: 601px) {
  .service_cards_elements {
    width: 60%;
  }
}

@media (min-width: 720px) {
  .service_cards_elements {
    width: 45%;
  }
}

@media (min-width: 970px) {
  .service_cards_elements {
    width: 35%;
  }
}

@media (min-width: 1024px) {
  .service_cards_elements {
    width: 35%;
  }
}

@media (min-width: 1200px) {
  .service_cards_elements {
    width: 25%;
  }
}
