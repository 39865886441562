/* Основные стили модального окна */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-overlay.open {
  display: flex;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

button[type='submit'] {
  margin-top: 20px;
  padding: 10px;
  width: 100%;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}

.cancel_btn {
  margin-top: 20px;
  width: 100%;
  padding: 10px;
  background-color: #9f9f9f;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}

.button_block {
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: space-between;
  padding: 10px 0;
  align-items: center;
}

button[type='submit']:hover {
  background-color: #218838;
}

h2 {
  margin-top: 0;
}

label {
  display: block;
  margin-top: 10px;
}

input[type='text'],
input[type='tel'],
input[type='number'] {
  width: calc(100% - 20px);
  padding: 10px;
  margin-top: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1em;
}

.error {
  color: red;
  font-size: 0.875em;
}

/* Стили для группы радио-кнопок */
.radio-group {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.radio-item {
  margin: 5px;
  cursor: pointer;
  text-align: center;
}

.radio-item img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
  border: 2px solid transparent;
}

.radio-item.selected img {
  border-color: #ffc900; /* Цвет выделения */
}

.radio-item input {
  display: none;
}

.radio-item label {
  display: block;
  margin-top: 5px;
  font-size: 1em;
}

/* Мобильная адаптация */
@media (max-width: 600px) {
  .modal-content {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
    overflow-y: auto;
  }

  .radio-group {
    align-items: center;
  }

  .radio-item {
    margin: 10px 0;
    width: 33%;
  }

  .radio-item img {
    width: 60px;
    height: 60px;
  }
  .close-button {
    top: 25px;
  }
}
